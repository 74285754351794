<template>
  <ClientOnly>
    <div class="flex flex-col">
      <VNavbar />
      <div class="flex flex-row w-full">
        <div class="hidden md:flex flex-col">
          <VSidebar />
        </div>
        <div class="flex flex-col w-full relative bg-[#f7f7f7]" >
          <slot />
        </div>
      </div>
    </div>
  </ClientOnly>
</template>
