export function useLogout() {
	const router = useRouter();
	const axios = useAxios();
  const toast = useCustomToast()


	function handleLogout() {
		useUserStore().logout();
		useActiveRetailChainAndPosStore().$reset();
		useAuthStore().$reset();
		useUserStore().$reset();
		useSaleFlow().$reset();
		useVivoMovelV1Store().$reset();
		useDeviceStoreV1().$reset();
		useTerraInternetFixaV1Store().$reset();
		useTerraMovelV1Store().$reset();
		useHomePlanStore().$reset();

    signOut()
	}

	function signOut() {
		axios.post("auth/sign-out").then(() => {
			localStorage.clear();
      sessionStorage.clear()

      toast.success("Logout realizado com sucesso!");

			router.replace("/login");
		});
	}
	return {
		handleLogout,
	};
}
