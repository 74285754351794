<template>
  <USlideover :model-value="isOpen" side="right" @close="$emit('close')">
    <UCard>
      <template #header>
        <div class="flex flex-row gap-2 items-center justify-between">
          <div class="flex flex-col">
            <div class="flex flex-row items-center gap-4">
              <h1>Configurações da conta</h1>
            </div>
          </div>
          <div class="flex flex-col">
            <UIcon
              name="i-heroicons-x-mark"
              class="cursor-pointer text-2xl"
              @click="$emit('close')"
            />
          </div>
        </div>
      </template>

      <div class="flex flex-col gap-2 text-sm">
        <div class="flex flex-row py-2">
          <p class="text-title uppercase font-semibold">Informações Pessoais</p>
        </div>
        <div class="grid flex-row grid-cols-12 py-2">
          <LazyVAccountSideSliderInformationSection
            title="CPF"
            :content="cpf_cnpj_mask(userStore.user?.cpf || '')"
          />
        </div>
        <div class="grid flex-row grid-cols-12 py-2">
          <LazyVAccountSideSliderInformationSection
            title="Nascimento"
            :content="new Date(userStore.user?.birth).toLocaleDateString()"
          />
          <LazyVAccountSideSliderInformationSection
            title="Gênero"
            :content="userStore.user.gender ? 'Masculino' : 'Feminino'"
          />
        </div>
        <div class="flex flex-row py-2">
          <p class="text-title uppercase font-semibold">
            Informações de contato
          </p>
        </div>
        <div class="grid flex-row grid-cols-12 py-2">
          <LazyVAccountSideSliderInformationSection
            title="Telefone"
            :content="phoneMask(userStore.user?.phone || '')"
          />
          <LazyVAccountSideSliderInformationSection
            title="Email"
            :content="userStore.user?.email || ''"
          />
        </div>
        <div class="flex flex-row py-2">
          <p class="text-title uppercase font-semibold">
            Informações de Perfil
          </p>
        </div>
        <div class="grid flex-row grid-cols-12 py-2 gap-4">
          <LazyVAccountSideSliderInformationSection
            title="Perfil"
            :content="getFormattedRole"
          />
        </div>
        <div class="flex flex-row py-2">
          <p class="text-title uppercase font-semibold">Conta bancaria</p>
        </div>
        <div class="grid flex-row grid-cols-12 py-2 gap-4">
          <LazyVAccountSideSliderInformationSection
            title="Banco"
            :content="userStore.user?.bank_account?.bank?.name || ''"
          />
          <LazyVAccountSideSliderInformationSection
            title="Conta"
            :content="userStore.user?.bank_account?.agency || ''"
          />
          <LazyVAccountSideSliderInformationSection
            title="Agência"
            :content="userStore.user?.bank_account?.agency || ''"
          />
          <LazyVAccountSideSliderInformationSection
            title="Digito"
            :content="userStore.user?.bank_account?.digit || ''"
          />
        </div>
      </div>
    </UCard>
  </USlideover>
</template>

<script setup lang="ts">
import { AVAILABLE_ROLES, cpf_cnpj_mask, phoneMask } from "~/shared/helpers";

const userStore = useUserStore();
withDefaults(
  defineProps<{
    isOpen: boolean;
  }>(),
  {
    isOpen: false,
  },
);

defineEmits(["close"]);

const getFormattedRole = computed(() => {
  return (
    AVAILABLE_ROLES[
      userStore.user?.roles?.[0].role as keyof typeof AVAILABLE_ROLES
    ] ||
    userStore.user?.roles?.[0].role ||
    ""
  );
});
</script>
