<template>
  <div class="flex flex-row">
    <UFormGroup label="PDV" class="w-full" name="pos.uuid">
      <USelectMenu
        v-model="form.pos"
        :searchable="true"
        placeholder="Selecione um PDV"
        size="xl"
        label="PDV"
        :options="posOptions"
        option-attribute="name"
        :loading="isPosLoading"
        :popper="{
          adaptive: true,
          overflowPadding: 12,
          strategy: 'absolute',
        }"
        :disabled="!form.retail_chain || posOptions?.length === 0"
        @change="$emit('change', $event)"
      />
    </UFormGroup>
  </div>
</template>

<script lang="ts" setup>
import { useQuery } from "@tanstack/vue-query";
import { nanoid } from "nanoid";
import { getPosByRetailChainsService } from "~/services/pos/get-pos-by-retail-chains";

defineEmits(["change"]);

const { form } = defineProps({
  form: {
    type: Object,
    required: true,
  },
});

const axios = useAxios();

const {
  data: posOptions,
  isLoading: isPosLoading,
  refetch,
} = useQuery({
  queryKey: ["pos", form.retailChain?.uuid || nanoid()],
  queryFn: async () => {
    if (!form.retail_chain?.searchable) return [];
    const { data } = await getPosByRetailChainsService(
      axios,
      form.retail_chain.searchable,
    );
    return data.data.pos;
  },
});

watchEffect(() => {
  if (!form.retail_chain?.searchable) {
    form.pos = "";
    return;
  }

  if (form.retail_chain) {
    refetch();
  }
});
</script>
